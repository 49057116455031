<script>
import PictureInput from 'vue-picture-input'
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Multiselect from 'vue-multiselect'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { BASE_IMAGE_URL } from '@src/config/configs'
import moment from 'moment-timezone'

// import PageHeader from '@components/page-header'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Banner',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    PictureInput,
    vueDropzone: vue2Dropzone,
    Multiselect,

    // PageHeader
  },
  data() {
    return {
      datepicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        altformat: 'Y-m-d',
        minTime: '00:00',
      },
      baseImageUrl: BASE_IMAGE_URL,
      form: {
        category_name: '',
        description: '',
        image: '',
        statusActive: false,
        // startTime: '',
        startDate: '',
        video: '',
      },
      isEdit: false,
      ismanuallyadd: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 380,
        maxFilesize: 10,
        maxFiles: 1,
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      options: [
        { value: false, text: 'Not Active' },
        { value: true, text: 'Active' },
      ],
    }
  },

  validations: {
    form: {
      category_name: {
        required,
      },
      description: {
        required,
      },
    },
  },
  mounted() {
    this.initialUpdateItem()
  },
  methods: {
    vfileAdded(file) {
      this.form.image = file
      this.ismanuallyadd = false
    },
    vfileremove() {
      this.form.image = ''
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },
    vfileAdded(file) {
      this.form.image = file
      this.ismanuallyadd = false
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    async formSubmit() {
      if (!this.form.image) {
        this.$toast.error('Image field must not be empty ')
        return
      }

      //   var thailandTime = moment.tz(this.form.startDate, 'Asia/Bangkok')
      //   console.log(thailandTime.format())

      let body = {
        video: this.form.video,
        // startTime: moment(this.form.startTime, ['HH:mm']).format('h:mm A'),
        startDate: this.form.startDate,
        description: this.form.description,
        statusActive: this.form.statusActive,
      }
      const data = new FormData()
      if (!this.ismanuallyadd) {
        data.append('image', this.form.image)
      }

      data.append('data', JSON.stringify(body))
      const queryId = this.$route.query.id
      const request = !this.isEdit
        ? axios.post('/api/banner', data)
        : axios.patch(`/api/banner/${queryId}`, data)

      await request
        .then((result) => {
          this.$toast.success('Saved successfully')
          this.$router.back()
        })
        .catch((err) => {
          this.$toast.error(err.response.data.error.message)
        })
    },

    async initialUpdateItem() {
      const id = this.$route.query.id
      if (!id) return

      await axios.get(`/api/banner/${id}`).then((res) => {
        this.form = { ...this.form, ...res.data }
        if (res.data.startDate) {
          console.log(res.data.startDate, 'time')
          this.form.startDate = res.data.startDate.substring(0, 16)
        }

        // if (res.data.startTime) {
        //   this.form.startTime = moment(res.data.startTime, ['h:mm A']).format(
        //     'HH:mm'
        //   )
        // }
        this.isEdit = true

        var file = { size: 123, name: 'Icon', type: 'image/JPG' }
        var url = `${this.baseImageUrl}/${this.form.imageBanner}`
        this.$refs.myVueDropzone.manuallyAddFile(file, url)
      })
    },
    onfocusin() {
      console.log(55555, this.$refs.dp1)
      //   this.$refs.dp1.active()

      this.$refs.dp1.datepicker.show()
      //   setTimeout((ev) => {
      //     this.$refs.dp1.isOpen || this.$refs.dp1.showCalendar(ev)
      //   }, 50)
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Create Banner</h4>
      </div>
      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-form class="form-horizontal" @submit.prevent="formSubmit">
                <div class="row center">
                  <div class="col-7">
                    <label>
                      Image
                      <small class="color-text-small"
                        >Width 700 x Height 300. Just only one.</small
                      >
                    </label>

                    <b-form-group label-cols-lg="0">
                      <vue-dropzone
                        @vdropzone-file-added="vfileAdded"
                        @vdropzone-file-added-manually="vfilemanuallyAdded"
                        @vdropzone-removed-file="vfileremove"
                        id="image"
                        ref="myVueDropzone"
                        :options="dropzoneOptions"
                      ></vue-dropzone>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Video ID</label>
                      <b-form-input
                        id="title"
                        v-model="form.video"
                        name="title"
                        type="text"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>

                    <div class="py-2">
                      <iframe
                        v-if="this.form.video_data"
                        class="center"
                        :src="
                          `https://player.vimeo.com/video/${this.form.video_data.vimeoid}`
                        "
                        width="640"
                        height="360"
                        frameborder="0"
                        allow="  fullscreen"
                        allowfullscreen
                      ></iframe>

                      <div>
                        <a
                          v-if="this.form.video_data"
                          :href="
                            `https://vimeo.com/manage/${this.form.video_data.vimeoid}/general`
                          "
                          target="_blank"
                          >Edit Video</a
                        >
                      </div>
                    </div>

                    <b-form-group label-cols-lg="0">
                      <label>Start Date</label>
                      <!-- <b-form-input
                          id="title"
                          v-model="form.startDate"
                          name="title"
                          type="date"
                          aria-describedby="input-1-live-feedback"
                        ></b-form-input> -->

                      <!-- @focusin.native="onfocusin" -->
                      <!-- <b-form-input
                        ref="dp1"
                        button-only
                        name="input-data_joined"
                        type="datetime-local"
                        class="mb-2"
                        v-model="form.startDate"
                        aria-readonly=""
                      /> -->
                      <flat-pickr
                        class="class-date"
                        v-model="form.startDate"
                        :config="datepicker"
                      ></flat-pickr>
                    </b-form-group>

                    <!-- <b-form-group label-cols-lg="0" class="col-xl-6 pr-0">
                        <label>Start Time</label>
                        <b-form-timepicker
                          v-model="form.startTime"
                          locale="en"
                        ></b-form-timepicker>
                        <b-form-input
                          id="title"
                          v-model="form.startTime"
                          name="title"
                          type="time"
                          aria-describedby="input-1-live-feedback"
                          locale="en"
                        ></b-form-input>
                      </b-form-group> -->

                    <b-form-group label-cols-lg="0">
                      <label>Description</label>

                      <b-form-textarea
                        id="textarea"
                        placeholder="Enter something..."
                        rows="3"
                        max-rows="6"
                        v-model="form.description"
                      ></b-form-textarea>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Status</label>

                      <div>
                        <b-form-select
                          v-model="form.statusActive"
                          :options="options"
                        ></b-form-select>
                      </div>
                    </b-form-group>

                    <b-form-group class="pt-5">
                      <button
                        type="button"
                        class="btn btn-danger width-lg rounded-pill aligncenter float-left"
                        @click="
                          () => {
                            this.$router.back()
                          }
                        "
                      >
                        <feather type="x"></feather>Cancel
                      </button>

                      <button
                        type="submit"
                        class="btn btn-primary width-lg rounded-pill aligncenter float-right"
                      >
                        <feather type="file-plus"></feather>
                        <span>{{
                          this.$route.query.id ? 'save' : 'Create'
                        }}</span>
                      </button>
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style lang="scss">
.class-date {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4b4b5a;
  vertical-align: middle;
  border: 1px solid #e2e7f1;
  border-radius: 0.3rem;
}
</style>
